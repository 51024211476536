.template-home {
    .banner {
        @include banner;
        &__content {
            padding-left: $pd-l;
            max-width: 50vw;
            padding-top: 19.5vh;
        }
        &__link {
            margin-top: 45px;
        }
        &__text {
            h1 {
                font-size: $fz-70;
                line-height: 1;
                margin-top: 0;
            }
            h2 {
                font-size: $fz-50;
                line-height: 1;
            }
            h3 {
                font-size: $fz-30;
                line-height: 1;
            }
            h4 {
                font-size: $fz-22;
                margin-bottom: 15px;
                line-height: 1;
            }
            p {
                color: $gray;
                font-size: $fz-14;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
            }
        }
    }
    .about {
        @include description;
    }
    .servicios {
        @include grid-posts;
    }
    .cifras {
        padding: 55px $pd-l;
        &__title {
            font-family: $f;
            text-align: center;
            font-size: $fz-30;
            font-weight: 600;
        }
        &__items {
            margin-top: 55px;
            display: grid;
            gap: 20px;
            flex-direction: row;
            justify-content: space-between;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .cifra {
            &__title {
                font-size: $fz-14;
                font-weight: bold;
                color: $gray;
            }
            &__num {
                font-family: $f;
                color: $blue;
                font-weight: bold;
                font-size: $fz-50;
            }
        }
    }
    .testimonios {
        padding: 0 $pd-l;
        margin-top: 100px;

        .testimonio {
            display: flex;
            position: relative;
            &__title {
                font-size: $fz-14;
                color: $blue;
                margin-bottom: 30px;
            }
            &__text {
                background-color: #94d5de;
                padding: 50px 7vw 50px 22vw;
                margin-left: 7vw;
            }
            &__nombre {
                margin-top: 30px;
                font-size: $fz-30;
                font-weight: 600;
                color: #fff;
            }
            &__descr {
                font-size: $fz-50;
                font-weight: 600;
                color: #fff;
            }
            &__especialidad {
                font-family: $f;
                font-size: $fz-20;
                color: #fff;
            }
            &__img {
                position: absolute;
                bottom: 0;
                height: 118%;
                width: auto;
            }
        }
        @media #{$media-mobile} {
            .testimonio {
                &__descr {
                    font-size: 30px;
                }
                &__nombre {
                    font-size: 20px;
                }
                &__especialidad {
                    font-size: 18px;
                }
            }
        }
    }
    .news {
        margin: 55px 0;
        .swiper-wrapper {
            gap: 30px;
            height: fit-content;
        }
        &__content {
            padding: 0 $pd-l;
        }
        &__title {
            font-family: $f;
            font-size: $fz-50;
            font-weight: 600;
            color: $blue;
        }
        &__info {
            margin-bottom: 60px;
        }
        .new {
            width: 100%;
            &__img {
                height: 23vh;
            }
            &__date {
                margin-top: 15px;
                margin-bottom: 10px;
                color: $blue;
                font-size: $fz-14;
            }
            &__title {
                font-size: $fz-22;
                font-weight: 600;
                color: #000;
                line-height: 1.08;
                font-family: $f;
                margin-bottom: 17px;
            }
            &__descr {
                font-size: $fz-14;
                color: $gray;
                line-height: 1.36;
            }
        }
        @media #{$media-mobile} {
            .new {
                margin-bottom: 40px;
            }
        }
    }
    .agenda {
        background-color: $blue;
        &__content {
            padding: 50px 20px;
        }
        &__text {
            text-align: center;

            h3 {
                font-family: $f;
                color: #fff;
                font-size: $fz-50;
                font-weight: 600;
                margin-bottom: 40px;
            }
            p {
                font-family: $f;
                font-size: $fz-22;
                color: #94d5de;
                max-width: 37%;
                margin: auto;
            }
        }
        &__btn {
            text-align: center;
            margin-top: 60px;
        }
        &__links {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            gap: 40px;
        }
        &__link {
            font-family: $f;
            font-size: $fz-20;
            color: #fff;
        }
    }
    .aseguradoras {
        &__content {
            padding: 50px 20px;
        }
        &__title {
            text-align: center;
            font-family: $f;
            font-size: $fz-30;
            font-weight: 600;
        }
        &__items {
            margin-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5.5vw;
            flex-wrap: wrap;
            img {
                width: 130px;
                height: auto;
            }
        }
    }
    @media #{$media-tablet} {
        .banner {
            &__content {
                max-width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                padding-top: unset;
                padding-right: $pd-l;
            }
        }
        .testimonios {
            padding: 0 20px;
            margin-top: 55px;
            .testimonio {
                &__text {
                    padding: 50px 7vw 50px;
                    margin-left: 0;
                }
                &__img {
                    display: none;
                }
            }
        }
        .news {
            &__content {
                padding: 0 20px;
            }
        }
        .agenda {
            &__text {
                p {
                    max-width: 80%;
                }
            }
        }
        .cifras {
            &__items {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
    @media #{$media-mobile} {
        .servicios {
            &__info {
                margin-bottom: 40px;
            }
        }
        .news .swiper-wrapper {
            display: block;
        }
    }
}
