// Basics ----------------
@import "reset";
@import "fonts";
@import "variables";
@import "mixins";
@import "base";

// Sections ----------------
@import "sections/header";
@import "sections/menu";
@import "sections/footer";

// Components ----------------
@import "components/buttons";
@import "components/form";

// Templates ----------------
@import "templates/home";
@import "templates/nosotros";
@import "templates/servicios";
@import "templates/single-service";
@import "templates/contact";
@import "templates/politicas";