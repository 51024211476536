.btn {
  background-color: $blue;
  border-radius: 100px;
  padding: 0.8vw 1.6vw;
  color: #fff;
  border: 2px solid $blue;
  transition: all 0.5s ease;
  @media #{$media-mobile} {
    padding: 10px 25px;
  }
  &:hover {
    background-color: transparent;
    border: 2px solid $blue;
    color: $blue;
  }
  &--transparent {
    background-color: transparent;
    border: 2px solid $blue;
    color: $blue;
    &:hover {
      color: #fff;
      border: 2px solid $blue;
      background-color: $blue;
    }
  }
  &--white {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    &:hover {
      color: #fff;
      border: 2px solid $blue;
      background-color: $blue;
    }
  }

  &--big {
    padding: 0.8vw 3.2vw;
    @media #{$media-tablet} {
      padding: 10px 25px;
    }
  }
}
.btn-modal {
  cursor: pointer;
}
.swiper-button-next,
.swiper-button-prev {
  color: $blue !important;
}
/* Icon 1 */

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 40px;
  height: 25px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: $blue;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Icon 2 */

#nav-icon2 {
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: $blue;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 10px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 20px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 17px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 17px;
}
