.citaform {
    padding: 20px;
    &.step1 {
        max-width: 680px;
    }
    max-width: 1125px;
    margin: auto;
    &__title {
        font-size: 30px;
        color: $blue;
        text-align: center;
    }
    &__nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 35px;

        .btn {
            font-size: 15px;
            text-align: center;
            background-color: #dbdbdb;
            color: #fff;
            border: none;
            padding: 12px 25px;
            &.active {
                background-color: $blue;
            }
        }
    }
    &__subtitle {
        margin-top: 25px;
        color: $blue;
        font-size: 22px;
        line-height: 1.36;
        text-align: center;
    }
    &__descr {
        margin-top: 25px;
        color: $gray;
        font-size: 22px;
        line-height: 1.36;
        text-align: center;
    }
    button{
        cursor: pointer;
    }
    .form {
        input,
        label,
        select {
            display: block;
        }
        label {
            margin-bottom: 10px;
            font-size: 16px;
        }
        select, textarea,
        input:not([type="submit"]):not([type="checkbox"]):not(.btn-pro) {
            margin-bottom: 25px;
            width: 100%;
            border-radius: 5px;
            border: solid 1px #d4d4d4;
            background-color: #fff;
            padding: 14px 21px;
            color: $gray;
            font-size: 16px;
        }
        &__buttons {
            display: flex;
            width: 100%;
            justify-content: space-between;
            color: $blue;
            margin-top: 30px;
            font-size: 20px;
        }
        input[type="checkbox"] {
            -webkit-appearance: none;
            width: 15px;
            height: 15px;
            background: #f3f3f3;
            border-radius: 50%;
            border: 2px solid #c3c3c3;
            display: inline-block;
        }
        input[type="checkbox"]:checked {
            background: $blue;
        }
        label[for="id_step3-accept_terms"] {
            position: relative;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #4a4a4a;
        }

        .label-pro {
            position: relative;
            cursor: pointer;
        }
        .label-pro input {
            display: none;
            
        }
        .label-pro span {
            background: $blue;
            opacity: 0.5;
            width: 118px;
            height: 40px;
            position: absolute;
            overflow: hidden;
            text-align: center;
            border-radius: 20px;
            left: 0;
            top: 50%;
            margin-top: -7.5px;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            transition: all 0.5s ease;

        }
        .btn-pro:checked + span {
            background: $blue;
            border-color: $blue;
            opacity: 1;
        }

        .protable {
            &.mobile {
                display: none;
            }
            th{
                vertical-align: middle;
                font-size: 16px;
            }
            td {
                color: $gray;
                padding: 20px 65px 47px 0;
                font-size: 15px;
                &:has(.label-pro){
                    padding-right: 0;
                }
                &.protable__name {
                    color: $blue;
                }
                &:not(.empty){

                    border-bottom: 1px solid #dbdbdb;
                }
                &.empty{
                    width: 118px;
                }
            }
            &__obs{
                font-family: $f;
                & > *{
                    font-family: $f;

                }
            }
        }
        &__profesional{
    
             display: none;
        }
    }
    @media #{$media-mobile}{
        .form .protable{
            &.desktop {
                display: none;
            }
            &.mobile {
                display: table;
                td,th{
                    padding: 20px 10px 0;
                    &.last{
                        border-bottom: 0;
                        padding-bottom: 60px;
                    }
                }
            }
            td,th {
                font-size: 14px;
                padding: 20px 10px;
            }
        }
        .citaform__nav {
            flex-direction: column;
            gap: 15px;
            .btn{
                font-size: 14px;
            }
        }
        .citaform__descr{
            font-size: 16px;
        }
        button,input[type="submit"]{
            font-size: 14px;
        }
        .form__buttons{
            margin-top: 45px;
        }
    }
}
