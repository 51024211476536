// Media queries
$media-desktop: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 990px)";
$media-mobile: "only screen and (max-width : 767px)";
$media-mobile-sm: "only screen and (max-width : 375px)";

// Colors

$blue: #0090a3;
$gray: #919191;
$gray-clear: #f8f8f8;

// Spacing
$pd-l: clamp(20px, 11.5vw, 20vw);

// Fonts
$f: "Sofia Pro";
$f-semi: "Sofia Pro Semi";
$f-ultra: "Sofia Pro Ultra";
$f-extra: "Sofia Pro Extra";

$medium: 400;
$sbold: 500;
$bold: 600;

// Font-sizes
$fz-13: clamp(13px, 0.9vw, 20px);
$fz-14: clamp(14px, 1vw, 30px);
$fz-16: clamp(16px, 1.15vw, 35px);
$fz-18: clamp(18px, 1.3vw, 35px);
$fz-20: clamp(20px, 1.4vw, 40px);
$fz-22: clamp(22px, 1.55vw, 45px);
$fz-25: clamp(22px, 1.75vw, 50px);
$fz-30: clamp(30px, 2.1vw, 60px);
$fz-50: clamp(40px, 3.5vw, 100px);
$fz-70: clamp(55px, 4.9vw, 140px);
