.template-nosotros {
  .banner {
    @include banner;
    background-position: center top;
  }
  .nosotros {
    padding: 55px $pd-l 22vh;
    &__title {
      h1 {
        color: $blue;
        font-size: $fz-14;
      
      }
    }
    &__texts {
      margin-top: 35px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2vw;
    }
    &__subtitle {
      margin-right: 12px;
      font-size: $fz-50;
      font-weight: bold;
      line-height: 1;
    }
    &__descr {
      font-size: $fz-14;
      line-height: 1.43;
    }
  }
  @media #{$media-tablet} {
    .banner{
      @media #{$media-tablet}  {
        height: 25vh;
    }
    }
    .nosotros {
      padding: 55px 20px;
      &__texts {
        grid-template-columns: 1fr;
      }
    }
  }
}
