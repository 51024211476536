.template-single-servicio {
    padding-bottom: 65px;
    .banner {
        @include banner;
    }
    .service {
        &__section {
            color: $blue;
        }
        &__content {
            padding: 45px $pd-l 75px;
            @include richtext;
        }
    }
    .cita {
        text-align: center;
        margin: 60px auto;
    }
    @media #{$media-mobile} {
        padding-bottom: 30px;
        .service {
            &__content {
                padding: 45px 20px 25px;
            }
        }
    }
}
