.header {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 12px;
    padding-left: $pd-l;
    padding-right: 8.4vw;
    background-color: #fff;
    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .ham-icon {
        display: none;
    }
    .header__mobile {
        display: none;
    }
    .logo img {
        width: 11.7vw;
        height: auto;
    }
    .nav {
        display: flex;
        gap: 3vw;
    }
    .menu {
        display: flex;
        align-items: center;
        gap: 5px;
        &__link {
            font-family: $f;
            font-size: $fz-13;
            color: #000;
           
            &.active {
                color: $blue;
            }
            &:not(.link-cita){
                font-family: $f;
                font-size: $fz-13;
                padding: 0.8vw 1.6vw;
                color: #000;
                border: 2px solid transparent;
                &.active {
                    color: $blue;
                }
                &:hover {
                   
                    color: $blue;
                 
                }
            }
        }
    }
    .redes {
        display: flex;
        align-items: center;
        gap: 14px;
        &__img {
            width: 32px;
            height: auto;
        }
    }
    @media #{$media-tablet} {
        padding-left: 20px;
        padding-right: 20px;
        &__content {
            nav.nav {
                display: none;
            }
        }
        .ham-icon {
            display: block;
        }
        .logo img {
            height: 40px;
            width: auto;
        }
        .header__mobile {
            height: 100%;
            .menu {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 0;
            }
            .nav-mobile {
                padding: 30px 0;
                nav {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
            nav ul li {
                margin: 20px 0;
            
            }
            .menu__link{
                font-size: 18px;
            }

            .btn {
                padding: 10px 20px;
                width: 100%;
            }
        }
    }
}
