.template-contacto {
    background-color: $gray-clear;
    .contacto {
        padding: 55px $pd-l 75px;

        &__content {
            max-width: 60vw;
            margin: 0 auto;
        }
        &__title {
            text-align: center;
            margin-top: 0;
            color: $blue;
            font-size: $fz-70;
        }

        &__wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 9vw;
        }
    }
    .info {
        font-size: $fz-18;
        &__subtitle {
            color: $blue;
            font-weight: bold;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        &__text {
            line-height: 1.22;
            letter-spacing: normal;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            color: #000;
        }
        & > div {
            margin-bottom: 50px;
        }
    }
    .form {
        &__descr {
            color: $gray;
            font-size: $fz-22;
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
        }
        form {
            margin-top: 18px;
            input:not([type="submit"]):not([type="checkbox"]),
            textarea {
                font-size: $fz-20;
                background-color: #fff;
                width: 100%;
                padding: 18px 16px;
                border-radius: 5px;
                margin-bottom: 30px;
                &::placeholder {
                    color: #000;
                    letter-spacing: 0.45px;
                }
            }

            input[type="submit"] {
                min-width: 200px;
                text-align: center;
                font-size: $fz-25;
                margin-top: 33px;
            }

            input[type="checkbox"] {
                -webkit-appearance: none;
                width: 15px;
                height: 15px;
                background: #f3f3f3;
                border-radius: 50%;
                border: 1px solid #d4d4d4;
                display: inline-block;
            }
            label {
                display: inline-block;
            }

            input[type="checkbox"]:checked {
                background: $blue;
            }
            .form__policy {
                font-size: $fz-14;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #4a4a4a;
                padding-bottom: 2px;
            }
        }
    }
    @media #{$media-tablet} {
        .contacto {
            &__content {
                max-width: 100%;
            }
            &__wrapper {
                display: flex;
                flex-direction: column-reverse;
            }
            .info {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                &__contacto {
                    align-self: center;
                }
            }
        }
    }
    @media #{$media-mobile} {
        .contacto {
            padding: 55px 20px;
            .form form input[type="submit"] {
                width: 100%;
                padding: 10px 0;
            }
            .form{
                &__descr{
                    font-size: 18px;
                }
            }
            .info {
                display: block;
            }
        }
    }
}
