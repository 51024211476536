@font-face {
    font-family: 'Sofia Pro Extra';
    src: url('../fonts/SofiaPro-ExtraLightitalic.eot');
    src: url('../fonts/SofiaPro-ExtraLightitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-ExtraLightitalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-ExtraLightitalic.woff') format('woff'),
        url('../fonts/SofiaPro-ExtraLightitalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-ExtraLightitalic.svg#SofiaPro-ExtraLightitalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Semi';
    src: url('../fonts/SofiaPro-SemiBold.eot');
    src: url('../fonts/SofiaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-SemiBold.woff2') format('woff2'),
        url('../fonts/SofiaPro-SemiBold.woff') format('woff'),
        url('../fonts/SofiaPro-SemiBold.ttf') format('truetype'),
        url('../fonts/SofiaPro-SemiBold.svg#SofiaPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Semi';
    src: url('../fonts/SofiaPro-SemiBolditalic.eot');
    src: url('../fonts/SofiaPro-SemiBolditalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-SemiBolditalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-SemiBolditalic.woff') format('woff'),
        url('../fonts/SofiaPro-SemiBolditalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-SemiBolditalic.svg#SofiaPro-SemiBolditalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Bolditalic.eot');
    src: url('../fonts/SofiaPro-Bolditalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Bolditalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-Bolditalic.woff') format('woff'),
        url('../fonts/SofiaPro-Bolditalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-Bolditalic.svg#SofiaPro-Bolditalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Lightitalic.eot');
    src: url('../fonts/SofiaPro-Lightitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Lightitalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-Lightitalic.woff') format('woff'),
        url('../fonts/SofiaPro-Lightitalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-Lightitalic.svg#SofiaPro-Lightitalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Ultra';
    src: url('../fonts/SofiaPro-UltraLight.eot');
    src: url('../fonts/SofiaPro-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-UltraLight.woff2') format('woff2'),
        url('../fonts/SofiaPro-UltraLight.woff') format('woff'),
        url('../fonts/SofiaPro-UltraLight.ttf') format('truetype'),
        url('../fonts/SofiaPro-UltraLight.svg#SofiaPro-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Regularitalic.eot');
    src: url('../fonts/SofiaPro-Regularitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Regularitalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-Regularitalic.woff') format('woff'),
        url('../fonts/SofiaPro-Regularitalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-Regularitalic.svg#SofiaPro-Regularitalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Extra';
    src: url('../fonts/SofiaPro-ExtraLight.eot');
    src: url('../fonts/SofiaPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-ExtraLight.woff2') format('woff2'),
        url('../fonts/SofiaPro-ExtraLight.woff') format('woff'),
        url('../fonts/SofiaPro-ExtraLight.ttf') format('truetype'),
        url('../fonts/SofiaPro-ExtraLight.svg#SofiaPro-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Mediumitalic.eot');
    src: url('../fonts/SofiaPro-Mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Mediumitalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-Mediumitalic.woff') format('woff'),
        url('../fonts/SofiaPro-Mediumitalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-Mediumitalic.svg#SofiaPro-Mediumitalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Light.eot');
    src: url('../fonts/SofiaPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Light.woff2') format('woff2'),
        url('../fonts/SofiaPro-Light.woff') format('woff'),
        url('../fonts/SofiaPro-Light.ttf') format('truetype'),
        url('../fonts/SofiaPro-Light.svg#SofiaPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Medium.eot');
    src: url('../fonts/SofiaPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Medium.woff2') format('woff2'),
        url('../fonts/SofiaPro-Medium.woff') format('woff'),
        url('../fonts/SofiaPro-Medium.ttf') format('truetype'),
        url('../fonts/SofiaPro-Medium.svg#SofiaPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Bold.eot');
    src: url('../fonts/SofiaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Bold.woff2') format('woff2'),
        url('../fonts/SofiaPro-Bold.woff') format('woff'),
        url('../fonts/SofiaPro-Bold.ttf') format('truetype'),
        url('../fonts/SofiaPro-Bold.svg#SofiaPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Black.eot');
    src: url('../fonts/SofiaPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Black.woff2') format('woff2'),
        url('../fonts/SofiaPro-Black.woff') format('woff'),
        url('../fonts/SofiaPro-Black.ttf') format('truetype'),
        url('../fonts/SofiaPro-Black.svg#SofiaPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Regular.eot');
    src: url('../fonts/SofiaPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Regular.woff2') format('woff2'),
        url('../fonts/SofiaPro-Regular.woff') format('woff'),
        url('../fonts/SofiaPro-Regular.ttf') format('truetype'),
        url('../fonts/SofiaPro-Regular.svg#SofiaPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Ultra';
    src: url('../fonts/SofiaPro-UltraLightitalic.eot');
    src: url('../fonts/SofiaPro-UltraLightitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-UltraLightitalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-UltraLightitalic.woff') format('woff'),
        url('../fonts/SofiaPro-UltraLightitalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-UltraLightitalic.svg#SofiaPro-UltraLightitalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Blackitalic.eot');
    src: url('../fonts/SofiaPro-Blackitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SofiaPro-Blackitalic.woff2') format('woff2'),
        url('../fonts/SofiaPro-Blackitalic.woff') format('woff'),
        url('../fonts/SofiaPro-Blackitalic.ttf') format('truetype'),
        url('../fonts/SofiaPro-Blackitalic.svg#SofiaPro-Blackitalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}