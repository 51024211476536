.template-grid {
  background-color: $gray-clear;
  &.template-equipo {
    .about {
      h1 {
        margin-bottom: 0;
      }
    }
  }
  .about {
    @include description;
    padding-bottom: 0;
  }

  .posts {
    @include grid-posts;
  }
}
