.footer {
    background-color: #fff;
    &__content {
        padding: 30px $pd-l 40px;
        display: flex;
        gap: 34px;
    }
    &__subtitle {
        color: $blue;
        font-size: $fz-14;
        font-family: $f;
        font-weight: bold;
    }
    &__text {
        font-size: $fz-14;
        line-height: 1.69;
        font-family: $f;
        color: #000;
    }
    &__tel {
        margin-top: 30px;
    }
    &__contacto {
        align-self: center;
        margin-left: 7vw;

    }
    @media #{$media-tablet} {
        &__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        &__contacto {
            margin-left: 0;
            align-self: baseline;
        }
    }
    @media #{$media-mobile}  {
        &__contacto {
           .btn{
            font-size: $fz-14;
           }
        }
    }
}
#cita-modal {
    position: fixed;
    background-color: #000;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: -10;
    inset: 0;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: all 0.5s ease;

    .cita-modal__content {
        position: relative;
        z-index: 12;
        background-color: #fff;
        width: 90vw;
        height: 80vh;
        margin: 80px auto auto;
        padding: 2vw 10vw;
        iframe {
            width: 100%;
            height: 100%;
            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none!important;
            scrollbar-width: none!important; /* Firefox */
        }
    }
    &.active {
        opacity: 1;
        z-index: 10;
    }
    .cita-modal__veil {
        position: absolute;
        inset: 0;
        top: 0;
        left: 0;
        z-index: 11;
        background-color: #000;
        opacity: 0.3;
    }
    .cita-modal__close {
        position: absolute;
        z-index: 14;
        top: 20px;
        right: 20px;
        color: $gray;
        font-size: $fz-30;
        cursor: pointer;
    }
    @media #{$media-tablet} {
        .cita-modal__content {
            padding: 2vw 20px;
        }
        .cita-modal__close{
            right: unset;
            left: 20px;
            font-size: 20px;
        }
       
    }
}
