@mixin richtext {
    h1 {
        font-size: $fz-50;
        color: $blue;
    }
    h2 {
        font-size: $fz-30;
        color: #000;
        margin-bottom: 15px;
    }
    h3 {
        font-size: $fz-22;
    }
    p {
        font-size: $fz-14;
        color: #4a4a4a;
        &.service__section {
            color: $blue;
            font-family: $f;
        }
    }
}
@mixin banner {
    height: 77vh;
    width: 100%;
    @media #{$media-tablet} {
        height: 40vh;
    }
}
@mixin description {
    padding: 55px $pd-l 75px;
    &__content {
        max-width: 60vw;
        text-align: center;
        margin: 0 auto;
        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-top: 0;
            color: $blue;
        }
        h1 {
            font-size: $fz-70;
        }
        h4 {
            font-size: $fz-30;
            margin-bottom: 15px;
        }
        p {
            color: $gray;
            line-height: 1.36;
            font-size: $fz-22;
        }
    }
    @media #{$media-tablet} {
        padding: 55px 20px 75px;
        &__content {
            max-width: 100%;
        }
    }
    @media #{$media-mobile} {
        padding: 55px 20px;
        &__content {
            p {
                font-size: 18px;
            }
        }
    }
}
@mixin grid-posts {
    background-color: $gray-clear;
    padding: 100px $pd-l;
    &__items {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

        @media (min-width: 1630px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &__title {
        font-family: $f;
        color: $blue;
        font-size: $fz-50;
        font-weight: 600;
    }
    &__descr {
        font-size: $fz-22;
        color: $gray;
        margin: 20px 0 40px;
        line-height: 1.36;
        @media #{$media-mobile} {
            p {
                font-size: 18px;
            }
        }
    }
    .item {
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
        height: 100%;
        &__img {
            height: 23vh;
        }
        &__content {
            padding: 17px 30px 27px;
        }
        &__title {
            color: $blue;
            font-size: $fz-20;
            margin-bottom: 12px;
            font-family: $f;
            font-weight: bold;
        }
        &__descr {
            font-size: $fz-14;
            color: $gray;
        }

        &--equipo {
            .item__img {
                height: 44vh;
            }
            .item__content {
                padding: 17px 30px 17px 13px;
                display: flex;
                flex-direction: row;
                justify-items: center;
                justify-content: space-between;
                align-items: center;
                svg {
                    width: clamp(32px, 2.2vw, 60px);
                    height: auto;
                }
            }
            .item__title {
                font-size: $fz-16;
                color: #000;
                margin-bottom: 0;
            }
            .item__descr {
                margin-top: 5px;
                p {
                    color: $blue;
                    font-size: $fz-14;
                    display: inline-block;
                }
            }
        }
    }
    @media #{$media-tablet} {
        padding: 55px 20px;
    }
}
