body.body-politicas {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    main{
        background-color: $gray-clear;
        flex:1
        
    }
    footer{
        justify-self: end;
    }

}
.template-politicas{
    @include description;
    .politicas{
        &__title{
            color: $blue;
        }
    }
}